<template>
  <div class="popup">
    <span class="title">お問い合わせ</span>
    <img class="logo" src="~/assets/img/world/inquiry_top.png"/>
    <p class="text">
      <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSeAE1aHZUXXsPlayFI0-E9dfnyLEnOjrSSyvlJaFk8nh630ew/viewform">問い合わせフォーム</a>から不具合の報告・ご意見お待ちしております。
    </p>  
  </div>
</template>

<script  setup>

</script>
<style lang="scss" scoped>
.popup{
  position: relative;
  background: #fff;
  height: 80%;
  width: 90%;
  padding: 30px;
  border-radius: 10px;
  margin: 0 auto;
  overflow-y: auto;
  border: 6px solid #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;;
  gap: 20px;
  &::-webkit-scrollbar {
    display: none; 
  }
  >.title{
    font-size: 20px;
    font-weight: bold;
    align-self: center;
  }
  >.logo{
    align-self: center;
    height: 50px;
  }
  >.sub{
    font-size: 18px;
    font-weight: bold;
  }
  >.text,>*>.text{
    font-size: 14px;
    margin-top: 5px;
    line-height: 1.5;
    font-weight: bold;
    >a{
      color: #86C2EB;
      text-decoration: underline;
    }
  }
  >*>.text{
    margin-left: 20px;
  }
  >*>.section-title{
    font-size: 16px;
    font-weight: bold;
  }
}
</style>